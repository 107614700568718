import axios from "axios";
import Globals from "../../config/globals";
import processLogs from "../../utils/processLogs";
import { LiveStatusActions, TodayAnalyticsActions } from "../actionTypes";

export const getTodaysLogs = (deviceId = "") => {
  return axios
    .get(`${Globals.EndPoints.DeviceServer}/v2/analytics/get_logs/${deviceId}`)
    .then((result) => {
      return {
        success: true,
        data: result.data,
        message: "Logs fetched",
      };
    })
    .catch((error) => {
      return {
        success: false,
        data: {
          error,
        },
        message: "Something went wrong",
      };
    });
};

export const isDeviceOnline = (deviceId) => {
  return axios
    .get(`${Globals.EndPoints.DeviceServer}/v2/admin/deviceOnline/${deviceId}`)
    .then(
      (result) => {
        return {
          success: true,
          message: "Status",
          data: { isOnline: result.data.status === "online" },
        };
      },
      (error) => {
        let message = String(error.message);
        if (error.response && error.response.data) {
          message = error.response.data.message;
        }
        return { success: false, message };
      }
    )
    .catch((error) => {
      return { success: false, message: String(error) };
    });
};

const loadTodayLogs = (payload = { deviceId: "", analytics: {} }) => ({
  type: TodayAnalyticsActions.LOAD,
  payload,
});

const setLiveStatusDevice = (payload = {deviceId: ''}) => ({
  type: LiveStatusActions.SELECT,
  payload,
})

export const loadTodaysLogs = (deviceId) => async (dispatch) => {
  const logsResponse = await getTodaysLogs(deviceId);
  if (!logsResponse.success) return;
  const processedData = processLogs(logsResponse.data);
  dispatch(setLiveStatusDevice({deviceId}));
  dispatch(loadTodayLogs({ deviceId, analytics: processedData }));
};
