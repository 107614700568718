import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import Globals from "../config/globals";
import { isDeviceOnline, loadTodaysLogs } from "../redux/actions/logs";

class CurrentStatusBox extends Component {
  componentDidMount() {
    this.refresh();
  }

  state = {
    occupied: false,
    loading: false,
  };

  refresh = () => {
    if (this.state.loading) return;
    const { deviceId } = this.props;
    this.setState({ loading: true });
    const onlineResponse = isDeviceOnline(deviceId);
    this.setState({
      occupied: onlineResponse.success && onlineResponse.data.isOnline,
      loading: false,
    });
  };

  onClick = () => {
    this.props.loadTodaysLogs(this.props.deviceId);
  };

  render() {
    const { occupied } = this.state;
    const { deviceId } = this.props;
    return (
      <Col md={4}>
        <div
          className={`rounded ${occupied ? "blue_elevated" : "elevated"} p-3`}
          style={{ cursor: "pointer" }}
          onClick={this.onClick}
        >
          Room {Globals.RoomDeviceConfig[deviceId]} <br />
          <span style={{ fontSize: "14px", color: "grey" }}>
            Device Id: {deviceId} <br />
          </span>
        </div>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { loadTodaysLogs })(CurrentStatusBox);
