import React from "react";
import SideBar from "../components/SideBar";
import { Route, Routes } from "react-router-dom";
import { Row } from "react-bootstrap";
import LiveStatus from "./LiveStatus";
import Header from "../components/Header";

const Dashboard = () => {
  return (
    <div>
      <Row className={"g-0"}>
        <div style={{ flex: 1 }}>
          <SideBar />
        </div>
        <div style={{ flex: 16 }}>
          <Header />
          <Routes>
            <Route path="/" element={<LiveStatus />} />
            <Route path="/liveStatus" element={<LiveStatus />} />
          </Routes>
        </div>
      </Row>
    </div>
  );
};

export default Dashboard;
