import Globals from "../../config/globals";
import { UserActions } from "../actionTypes";

const loginUser = (payload = { profile: {} }) => ({
  type: UserActions.LOGIN,
  payload,
});

const logoutUser = () => ({
  type: UserActions.LOGOUT,
});

export const login =
  (username = "", password = "") =>
  (dispatch) => {
    if (!Globals.Users[username]) {
      return { success: false, message: "User does not exist" };
    }
    if (
      Globals.Users[username] &&
      Globals.Users[username].password !== password
    ) {
      return { success: false, message: "Wrong password" };
    }
    dispatch(loginUser({profile: { username, name: Globals.Users[username].name }}));
    return { success: true, message: "Logged in successfully" };
  };

export const logout = () => (dispatch) => {
  dispatch(logoutUser());
};
