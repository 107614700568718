let socketDetails = {};

/**
 * Decodes a connect log and store the details in socketDetails
 * {"transport":"websocket","node_mcu_id":"device2367","device_type":"node_mcu","strength":"-36"} yfE3dpqyq0IIykZFAABO 2021-04-25T19:45:09.596Z
 * @param {String} payload connect log of format "<query> <sockedIt> <timestamp>"
 */
const handleConnecting = (payload = "") => {
  const components = payload.match(/^({.*}) (.*) (.*)$/);
  const query = components[1];
  const socketId = components[2];
  const ontime = components[3];
  socketDetails[socketId] = {
    query,
    ontime,
    otime: new Date(ontime).toLocaleTimeString(),
  };
};

/**
 * Decodes a disconnect log and pairs it with the connect log in socketDetails
 * e.g. device7105 wdpGBvOaDqt5VpbzAACw server namespace disconnect 2021-04-27T13:25:10.395Z
 * @param {String} payload disconnect log of the format <deviceId> <socketId> <reason> <timestamp>
 */
const handleDisconnected = (payload = "") => {
  const components = payload.match(/^(\S*) (\S*) (.*) (\S*)$/);
  const socketId = components[2];
  const reason = components[3];
  const offtime = components[4];
  let existingData = socketDetails[socketId] || { ontime: offtime, query: {} };
  socketDetails[socketId] = {
    ...existingData,
    offtime,
    oftime: new Date(offtime).toLocaleTimeString(),
    reason,
  };
};

const processLogs = (data) => {
  socketDetails = {};
  let logs = data.split("\n");
  for (const log of logs) {
    if (!log.startsWith("INFO - ")) {
      continue;
    }
    let log_components = String(log).match(/^INFO - (\w*) (.*)/);
    const event = log_components[1];
    const payload = log_components[2];
    switch (event) {
      case "CONNECTING":
        handleConnecting(payload);
        break;
      case "DISCONNECTED":
        handleDisconnected(payload);
        break;
      default:
        break;
    }
  }
  const connections = Object.values(socketDetails).filter(
    (conn, index) => conn.offtime !== undefined || index.length === Object.values(socketDetails).length - 1
  );
  return {connections};
};

export default processLogs;
