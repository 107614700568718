import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrentStatusBox from "../components/CurrentStatusBox";
import LogsBox from "../components/LogsBox";
import Globals from "../config/globals";
import { isDeviceOnline } from "../redux/actions/logs";

class LiveStatus extends Component {
  componentDidMount() {}

  refresh = async () => {
    let isOnline = {};
    await new Promise((resolve) => {
      const deviceIds = Object.keys(Globals.RoomDeviceConfig);
      deviceIds.forEach((deviceId, index) => {
        isDeviceOnline(deviceId)
          .then((result) => {
            isOnline[deviceId] = result.success && result.data.isOnline;
          })
          .catch(() => {
            isOnline[deviceId] = false;
          })
          .finally(() => {
            if (index === deviceIds.length - 1) {
              resolve();
            }
          });
      });
    });
    this.setState({ occupiedStatus: isOnline });
  };

  render() {
    const deviceIds = Object.keys(Globals.RoomDeviceConfig);
    return (
      <div>
        <Row className={'g-0'}>
          <Col lg={9}>
            <Container className={'px-4 py-4'}>
              <Row className={"gx-3 gy-3"}>
                {deviceIds.map((deviceId) => (
                  <CurrentStatusBox key={deviceId} deviceId={deviceId} />
                ))}
              </Row>
            </Container>
          </Col>
          <Col lg={3} style={{borderLeft: '0.5px solid black'}}>
            <Container  className={'py-4'}>
              <LogsBox />
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LiveStatus;
