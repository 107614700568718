export const toMinutesAndSeconds = (timeDiff = 0) => {
  let time = Math.abs(timeDiff);
  let seconds = parseInt(time/1000);
  let minutes = parseInt(seconds/60);
  seconds = seconds%60;
  return `${minutes} min ${seconds} s`;
}

export const toHoursAndMinutes = (time = 0) => {
	let seconds = time/1000;
	let minutes = parseInt(seconds/60, 10);
	let hours = 0;
	let value = '';
	if (minutes > 60) {
		hours = parseInt(minutes/60, 10);
		minutes = minutes % 60;
	}
	if (hours > 0) {
		value += `${hours} hrs `;
	}
	if (minutes > 0) {
		value += `${minutes} mins`;
	}
	return value;
}