import { Component } from "react";
import { connect } from "react-redux";
import Globals from "../config/globals";
import { toHoursAndMinutes } from "../utils/functions";

class LogsBox extends Component {
  render() {
    const { liveStatus, todaysAnalytics } = this.props;
    const { deviceId } = liveStatus;
    const analytics = todaysAnalytics[deviceId];
    if (deviceId.length === 0) {
      return <div>Select a room to see today's logs</div>;
    }
    const { connections } = analytics;
    return (
      <div>
        <h5>Room {Globals.RoomDeviceConfig[deviceId]}</h5>
        {connections
          .filter((conn) => {
            if (!conn.offtime) return true;
            return (
              new Date(conn.offtime).getTime() -
                new Date(conn.ontime).getTime() >
              Globals.MinConnectionTime
            );
          })
          .map((conn) => {
            return (
              <div>
                <div className="rounded elevated">
                  <div>
                    User entered at :{" "}
                    <strong>
                      {" "}
                      {new Date(conn.ontime).toLocaleTimeString("en-IN", {
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                    </strong>
                  </div>
                  {conn.offtime && (
                    <div>
                      <div>
                        User stayed for :{" "}
                        <strong>
                          {toHoursAndMinutes(
                            new Date(conn.offtime).getTime() -
                              new Date(conn.ontime).getTime()
                          )}
                        </strong>
                      </div>
                      <div>
                        User left at :{" "}
                        <strong>
                          {" "}
                          {new Date(conn.offtime).toLocaleTimeString("en-IN", {
                            hour12: true,
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
                <br />
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  liveStatus: state.liveStatus,
  todaysAnalytics: state.todaysAnalytics,
});

export default connect(mapStateToProps)(LogsBox);
