import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import { useSelector } from 'react-redux';
import Login from './screens/Login';

function App() {
  const loggedIn = useSelector(state => state.user.loggedIn);
  if (!loggedIn) {
    return <Login />
  }
  return (
    <div>
      <BrowserRouter>
        <Dashboard />
      </BrowserRouter>
    </div>
  );
}


export default App;
