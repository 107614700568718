import React, { Component } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Images from "../config/images";
import { login } from "../redux/actions/user";

class Login extends Component {
  state = {
    username: "",
    password: "",
    message: "",
  };

  render() {
    const { message } = this.state;
    return (
      <div
        style={{
          backgroundImage: `url(${Images.LoginBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
        }}
      >
        <Container fluid>
          <Row>
            <Col></Col>
            <Col>
              <div className="login-form">
                <Form onSubmit={this.handleSubmit}>
                  <div style={{ textAlign: "center", marginBottom: "5%" }}>
                    <img
                      src={Images.Logo}
                      width="20%"
                      style={{ marginBottom: "2%" }}
                    />
                    <h3>Hospitality Portal</h3>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "10%",
                      fontSize: "15px",
                    }}
                  >
                    Centralised Property Management System
                    <br />
                    <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                      Seamless, Smart, Efficient
                    </div>
                  </div>
                  <Form.Group className="input-wrapper" controlId="username">
                    <input
                      size="lg"
                      className="input"
                      onChange={(e) => {
                        this.setUsername(e.target.value);
                      }}
                      type="username"
                      placeholder="Username"
                    />
                  </Form.Group>
                  <Form.Group
                    className="input-wrapper"
                    controlId="formBasicPassword"
                  >
                    <input
                      size="lg"
                      className="input"
                      onChange={(e) => {
                        this.setPassword(e.target.value);
                      }}
                      type="password"
                      placeholder="Password"
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          this.handleSubmit();
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
                <button onClick={this.handleSubmit} className="button">
                  LOGIN
                </button>
                {message && <div style={{ color: "red" }}>{message}</div>}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleSubmit = async () => {
    this.setState({ message: "" });
    const { username, password } = this.state;
    const response = await this.props.login(username, password);
    if (!response.success) {
      this.setState({ message: response.message });
    }
  };

  setPassword = (password) => {
    this.setState({ password });
  };

  setUsername = (username) => {
    this.setState({ username });
  };
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { login })(Login);
