import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "../redux/actions/user";

const Header = ({ user, logout }) => {
  console.log(user);
  return (
    <div
      style={{
        padding: '15px',
        borderBottom: "0.5px solid black",
      }}
    >
      <Row>
        <Col>
          <span style={{ fontSize: 24, fontWeight: 500 }}>
            Aliste Technologies Pvt. Ltd
          </span>
        </Col>
        <Col>
          <div style={{ float: "right", marginRight: '20px' }}>
            <span style={{ marginRight: 15 }}>
              Welcome, <strong> {user.profile?.name} ! </strong>
            </span>
            <Button size="sm" variant={"primary"} onClick={logout}>Logout</Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, {logout})(Header);
