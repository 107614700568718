const RoomDeviceConfig = {
	S011342: 201,
	S010604: 202,
	S011296: 203,
	S011458: 204,
	S011286: 205,
	S010975: 206,
	S011166: 207,
	S011290: 208,
	S011335: 209,
	S011438: 210,
	S011409: 302,
	S011418: 303,
	S011385: 304,
	S011346: 305,
	S011236: 306,
	S011377: 307,
	S011336: 308,
	S011440: 309,
	S011394: 310,
	S011298: 401,
	S011338: 402,
	S011433: 403,
	S011472: 404,
	S011312: 405,
	S011278: 406,
	S011388: 407,
	S011272: 408,
	S010938: 409,
};

const MinConnectionTime = 1 * 60 * 1000; // 1 min

const EndPoints = {
	DeviceServer: 'https://a3.alistetechnologies.com',
	LogServer: 'https://log.alistetechnologies.com'
}

const Users = {
	athiti: {name: 'Athiti', password: 'password'},	
};

const Globals = {
	EndPoints,
	RoomDeviceConfig,
	MinConnectionTime,
	Users,
}

export default Globals;
