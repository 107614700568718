import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../config/images";
import {Tooltip} from 'react-tooltip';


const SideBarItems = [
  {
    linkTo: "/",
    image: Images.MenuImages.Logo,
    toolTip: 'Home',
  },
	{
    linkTo: "/liveStatus",
    image: Images.MenuImages.LiveSatus,
    toolTip: 'Live Status',
  },
]

const SideBar = () => {
  return (
    <div style={{ borderRight: "0.5px solid black", height: "100vh" }}>
      <center>
        {SideBarItems.map((item) => {
          return (
            <React.Fragment key={item.linkTo}>
              <Link to={item.linkTo} data-tip={item.toolTip}>
                <Image
                  src={item.image}
                  style={{ width: "40px", margin: "15px 5px" }}
                />
              </Link>
              <br />
            </React.Fragment>
          )
        })}
      </center>
      <Tooltip offset={{right: 20}} place={'right'} effect={'solid'} />
    </div>
  );
};

export default SideBar;
