import { createStore, combineReducers, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import todaysAnalytics from "./reducers/todaysAnalytics";
import liveStatus from "./reducers/liveStatus";
import user from "./reducers/user";

const persistConfig = {
  key: "root",
  storage,
	blacklist: ['liveStatus']
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    liveStatus,
    todaysAnalytics,
		user,
  })
);

const store = createStore(persistedReducer, applyMiddleware(thunk, logger));

export const persistor = persistStore(store);
export default store;
