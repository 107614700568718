import { TodayAnalyticsActions } from "../actionTypes";

const todaysAnalytics = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case TodayAnalyticsActions.LOAD:
      return {
        ...state,
        [payload.deviceId]: payload.analytics,
      };
    case TodayAnalyticsActions.CLEAR:
      return {};
    default:
      return state;
  }
};

export default todaysAnalytics;
