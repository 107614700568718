import { UserActions } from "../actionTypes";

const initialState = {
  profile: { name: "", username: "" },
  loggedIn: false,
};
const user = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UserActions.LOGIN:
      return {
        ...state,
        profile: payload.profile,
        loggedIn: true,
      };
    case UserActions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default user;
