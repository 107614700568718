export const TodayAnalyticsActions = {
	LOAD: 'TODAY_ANALYTICS_LOAD',
	CLEAR: 'TODAY_ANALYTICS_CLEAR',
}

export const LiveStatusActions = {
	SELECT: 'LIVE_STATUS_SELECT',
	CLEAR: 'LIVE_STATUS_CLEAR',
}

export const UserActions = {
	LOGIN: 'USER_ACTIONS_LOGIN',
	LOGOUT: 'USER_ACTIONS_LOGOUT',
}