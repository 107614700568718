import { LiveStatusActions } from "../actionTypes";

const liveStatus = (state = { deviceId: "" }, action) => {
  const { type, payload } = action;
  switch (type) {
    case LiveStatusActions.SELECT:
      return {
        ...state,
        deviceId: payload.deviceId,
      };

    case LiveStatusActions.CLEAR:
      return {};

    default:
      return state;
  }
};

export default liveStatus;
